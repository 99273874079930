import React from 'react'
import { Link } from 'gatsby'

/**
 * Display a button
 */
const Button = props => {
  if (props.external === true || props.external === 'true') {
    // External Link
    return (
      <a
        href={props.url || '#'}
        className={`button ${props.type || 'is-link'} ${props.class || ''}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {props.text || 'Get a Callout'}
      </a>
    )
  } else {
    // Internal Link
    return (
      <Link
        to={props.url || '/contact/'}
        className={`button ${props.type || 'is-link'} ${props.class || ''}`}
      >
        {props.text || 'Get a Callout'}
      </Link>
    )
  }
}

export default Button
