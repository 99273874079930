import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { add } from 'cart-localstorage'
import Styles from '../../styles/Product.module.sass'

/**
 * Display a single product
 * 
 * @param {string}  slug
 */
const Product = props => {
  const data = useStaticQuery(posts)

  const maxQty = 50

  let product

  function addToOrderAction(id, title, price, quantity) {
    add({ id: id, name: title, price: price }, quantity)

    // Reload the page to update the floating cart
    // TODO: Research using Redux for this purpose in the future
    window.location.reload()
  }

  // Identify desired product
  data.allMdx.edges.some(function(e) { 
    let grabbedSlug = e.node.fields.slug.replace('/', '')

    if ( grabbedSlug === props.slug ) {
      product = e.node

      return true
    }
    else {
      return false
    }
  })

  // Set defauly quantity to 1
  const [qty, setQty] = useState(1)

  // Increase/Decrease quantity
  function changeQty(operation) {
    if (
      (qty <= 1 && operation === 'decrease') ||
      (qty >= maxQty && operation === 'increase')
    ) {
      return false
    }

    if (operation === 'decrease') {
      setQty(~~qty - 1)
    } else {
      setQty(~~qty + 1)
    }
  }

  // Change input quantity
  function inputQty(input) {
    if (input === '' || (~~input > 0 && ~~input <= maxQty)) {
      setQty(~~input)
    } else if (input < 1) {
      setQty(1)
    } else if (input > maxQty) {
      setQty(maxQty)
    }
  }

  return (
    <div className={`container is-wider-than-parent has-text-left ${Styles.container || ''}`}>    
      <div className={Styles.product || ''}>
        {product.fields.image ? (
          <Img
          className={Styles.image}
          fluid={product.fields.image.childImageSharp.fluid}
          />
        ) : null}

        <div className={Styles.vitals || ''}>
          <strong className={Styles.title || ''}>
            { product.frontmatter.title + ' ' + (product.frontmatter.forcedTypeName || props.type || product.frontmatter.productType) }
          </strong>
          <span className={Styles.peopleFed || ''}>
            For <i>{product.frontmatter.peopleFed}</i> guests
          </span>
          <div className={Styles.description || '' }
            dangerouslySetInnerHTML={{ __html: product.frontmatter.description }}
          />
        </div>

        <div className={Styles.addToOrder || ''}>
          <span className={Styles.price}>
            <i>&euro;</i>
            {product.frontmatter.price}
          </span>

          <div className={Styles.quantityWidget || ''}>
            <input
              type="text"
              name="quantity"
              value={qty}
              onChange={e => {
                inputQty(e.target.value)
              }}
            />
            <span
              className={`tag ${Styles.increase || ''}`}
              onClick={() => {
                changeQty('increase')
              }}
              onKeyDown={() => {
                changeQty('increase')
              }}
              role="button"
              tabIndex="0"
            >
              +
            </span>
            <span
              className={`tag ${Styles.decrease || ''}`}
              onClick={() => {
                changeQty('decrease')
              }}
              onKeyDown={() => {
                changeQty('decrease')
              }}
              role="button"
              tabIndex="0"
            >
              –
            </span>
          </div>

          <button
            className={`button ${Styles.addToOrderButton ||
              ''}`}
            onClick={() => {
              addToOrderAction(
                product.frontmatter.productID,
                product.frontmatter.title + ' ' + product.frontmatter.productType,
                product.frontmatter.price,
                qty
              )
            }}
          >
            Add to Order
          </button>
        </div>
      </div>
    </div>
  )
}

export default Product

export const posts = graphql`
  {
    allMdx(filter: {frontmatter: {templateKey: {eq: "product"}}}, sort: {fields: frontmatter___productID, order: ASC}) {
      edges {
        node {
          id
          fields {
            slug
            image {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          frontmatter {
            description
            title
            price
            productType
            forcedTypeName
            peopleFed
            pricePerPerson
            productID
          }
        }
      }
    }
  }
`